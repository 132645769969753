<template>
  <v-container>
    {{ customerCount }} customers have {{ shipmentCount }} shipments.
    <ul>
      <li v-for="(s,i) of shipmentSizes" v-bind:key="i">
        {{ i }} meal - {{ s }} shipments ({{ ((s / shipmentCount) * 100).toLocaleString() }}%)
      </li>
    </ul>
  </v-container>
</template>

<style scoped>

</style>


<script>
export default {
  name: "OrderStatistics",
  props: {
    orders: {type: Array, required: true}
  },
  computed: {
    customerCount() {
      return Object.keys(this.ordersByCustomer).length
    },
    shipmentCount() {
      return Object.values(this.ordersByCustomer)
        .reduce((sum, c) => Object.keys(c).length + sum, 0);
    },
    ordersByCustomer() {
      const result = {};
      this.orders.forEach(o => {
        result[o.uid] = result[o.uid] || {};
        result[o.uid][o.ship_date] = result[o.uid][o.ship_date] || [];
        result[o.uid][o.ship_date].push(o);
      });
      return result;
    },
    shipmentSizes() {
      const result = {};
      Object.values(this.ordersByCustomer)
        .forEach(orderByDate => {
          Object.values(orderByDate)
            .forEach(ordersOnDate => {
                const orderCountOnDate = ordersOnDate.length;
                result[orderCountOnDate] = result[orderCountOnDate] || 0;
                result[orderCountOnDate]++
              }
            )
        });
      return result;
    }
  }
}
</script>
