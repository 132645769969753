<template>
  <div>
    <v-progress-linear v-if="loading" :indeterminate="true"/>
    <v-container class="orders" fluid v-if="!loading">
      <v-dialog
        v-model="showStats"
        width="600px"
      >
        <v-card>
          <v-card-title>Order Statistics</v-card-title>
          <v-card-text>
            <OrderStatistics :orders="orders"/>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showStats=false">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showMissing"
        ref="dialogMissing"
        width="500px"
      >
        <v-card>
          <v-card-title>Warning</v-card-title>
          <v-card-text>
            The following meal ids were not found in the database. Any ingredients required for these meals is not show.
            <v-list>
              <v-list-item v-for="mealId of missing_meals" v-bind:key="mealId">
                {{ missing_count[mealId] }} orders for {{ mealId }}
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-footer fixed class="d-print-none pl-10 pr-8" v-if="datesFormatted">
        {{ datesFormatted }}
        <v-spacer/>
        {{ totalCountStream }} stream meals |
        {{ totalCount - (totalCountStream + totalCountExtras) }} other meals |
        {{ totalCount - totalCountExtras }} meals +
        {{ totalCountExtras }} snacks & extras |
        {{ orders.length }} orders
        <template v-if="missing_meals.size">
          <v-spacer/>
          <v-chip
            color="warning" class="mr-1"
            @click="showMissing=true"
          >{{ missing_meals.size }} meals not found
          </v-chip>
        </template>
      </v-footer>
      <v-toolbar flat class="d-print-none pb-10">
        <v-text-field :value="datesFormatted"
                      label="Select dates"
                      single-line
                      hide-details
                      readonly
                      @click="showDatePicker=true"
                      append-icon="mdi-calendar"
        />

        <v-spacer/>
        <v-select
          label="Filter by stream"
          v-model="streamsSelected"
          :items="streams"
          multiple
          single-line
          hide-details
          clearable
        />
        <v-spacer/>
        <v-select
          label="Filter by tod"
          v-model="todSelected"
          :items="tods"
          multiple
          single-line
          hide-details
          clearable
        />
        <v-spacer/>

        <v-text-field
          v-model="searchInput"
          append-icon="mdi-magnify"
          label="Search..."
          single-line
          hide-details
          @change="search=searchInput"
          clearable
        />
        <template v-if="isAdmin">
          <v-spacer/>
          <v-btn outlined @click="csvExport(csvAsTable(orders,headers,search),exportFilename())">Download CSV</v-btn>
        </template>
        <v-dialog
          v-model="showDatePicker"
          ref="dialog"
          width="290px"
          persistent
        >
          <v-date-picker
            v-model="datePickerDate"
            range
            no-title
            @close="closeDatePicker"
          ></v-date-picker>
          <v-btn @click="closeDatePicker">Close</v-btn>
        </v-dialog>
      </v-toolbar>
      <v-toolbar v-if="selected.length>0" flat>
        <v-btn
          class="mr-2"
          v-for="{chunk, emails} of getEmailChunks(selected,maxEmailPerLink)"
          v-bind:key="chunk"
          v-bind:href="`mailto:?bcc=${emails.join(',')}`"
        >
          Email {{ chunk }}
        </v-btn>
      </v-toolbar>


      <v-btn v-if="showTable" @click="showTable=false">Hide Table</v-btn>
      <v-container fluid class="fill-height">
        <v-container v-if="!showTable">
          <v-alert outlined color="info">Orders are hidden to improve performance</v-alert>
          <v-toolbar flat>
            <v-btn @click="showTable=!showTable">Show Table</v-btn>
            <v-spacer/>
            <v-btn @click="showStats=true">Show Stats</v-btn>
          </v-toolbar>
        </v-container>
        <v-data-table
          v-if="showTable"
          :class="showTable ? '' : 'd-none'"
          v-model="selected"
          :headers="headers"
          :items="orders"
          item-key="id"
          :disable-pagination=true
          :hide-default-footer=true
          :fixed-header=false
          :show-select=true
          v-on:click:row="rowClicked"
          :loading="loading"
          group-by="name"
          show-group-by
        >
          <template v-slot:group.header="{ items, group, groupBy }">
            <td :colspan="headers.length">
              {{ groupBy.join(', ') }}
              <strong>{{ group }}</strong> count {{ items.reduce((i, a) => a.quantity + i, 0) }}

              <span v-for="{chunk, emails} of getEmailChunks(items,maxEmailPerLink)" v-bind:key="chunk">
                <a v-if="groupBy.includes('meal_id') || groupBy.includes('date') "
                   v-bind:href="`mailto:?bcc=${emails.join(',')}`"
                   target="_blank"
                   class="mr-2"
                >
                  Email {{ chunk }}
                </a>
              </span>
            </td>

          </template>

          <template v-slot:item.date="{ item }">
            {{ dateFormatted(item.date) }}
          </template>
          <template v-slot:item.quantity="{ item }">
            <span v-if="readOnly">{{ item.quantity }}</span>
            <v-text-field
              v-else
              class="quantity-input"
              v-model="item.quantityEdit"
              type="number"
              min="0"
              @change="updateQuantity(item)"
            />
          </template>
        </v-data-table>
      </v-container>
    </v-container>
    <!-- blank gap at the bottom to avoid fixed footer hiding data-table -->
    <div class="ma-8"></div>

  </div>
</template>


<script>
import {mapActions, mapGetters} from 'vuex'
import moment from 'moment';
import {csvAsTable, csvExport, dateFormatted, deepCopy, escapeRegex, getDateString} from '@/store/utils'
import api from '@/api';
import urlState from "@/router/urlState";
import OrderStatistics from "@/components/OrderStatistics.vue";


export default {
  components: {OrderStatistics},
  mixins: [urlState],
  data() {
    return {
      units: ['g', 'kg'],
      decimalPlaces: [1, 2],
      respondToRouteChanges: true,
      streamsSelected: [],
      todSelected: [],
      selected: [],
      missing_meals: new Set(),
      missing_count: {},
      showDatePicker: false,
      dates: [],
      search: '',
      loading: false,
      post: null,
      error: null,
      multiview: false,
      multiviewMealSelected: {},
      headers: [
        // {
        //   text: 'Email',
        //   align: 'start',
        //   value: 'email',
        //   width: '*'
        // },
        {text: 'Quantity', value: 'quantity'},
        // {text: 'Id', value: 'id' },
        {text: 'Meal ID', value: 'meal_id'},
        {text: 'Bin', value: 'bin'},
        {text: 'Meal', value: 'mealName'},
        {text: 'Size', value: 'meal_size'},
        {text: 'Customer Name', value: 'name',},
        {text: 'Email', value: 'email',},
        {text: 'Stream', value: 'stream'},
        {text: 'Time of day', value: 'tod'},
        {text: 'First Week', value: 'isFirstOrder'},
        {text: 'Date', value: 'date'},
        {text: 'Allergy', value: 'displayAllergies'},
        {text: 'Substitution', value: 'is_substitution'}

      ],
      showMissing: false,
      setRouteTimeout: null,
      filteredIngredients: [],
      maxEmailPerLink: 450,
      searchInput: null,
      currentItems: [],
      showTable: null,
      datePickerDate: null,
      showStats: false,
    }
  },
  watch: {
// call again the method if the route changes
    search(val) {
      this.searchInput = val;
    },
    showDatePicker(v) {
      console.log('watch showDatePicker', v, this.dates, this.datePickerDate);
      if (v) {
        this.datePickerDate = this.dates;
      } else {
        this.dates = this.datePickerDate;
      }
    }
  },
  mounted() {
    this.syncToUrl({
      param: 'search', urlParam: 'search',
      initFromRoute: true
    });
    this.syncToUrl({
      param: 'streamsSelected', urlParam: 'streams',
      initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'todSelected', urlParam: 'tods',
      initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    this.syncToUrl({
      param: 'dates', urlParam: 'dates', initFromRoute: true,
      parseCallback: (v) => Array.isArray(v) ? v : [v]
    });
    return this.fetchData();
  },
  computed: {
    readOnly() {
      return !!this.$route.meta.readOnly;
    },
    ...mapGetters([
      'meals',
      'diets',
      'getMeal',
      'getMealIngredients',
      'getDietName',
      'getDietStream',
      'getTimeOfDay',
      'getSummaryForDiet',
      'getOrdersOnDate',
      'getCustomer'
    ]),
    ordersUnfiltered() {
      const orders = deepCopy(this.getDatesInRange()
        .flatMap(date => this.getOrdersOnDate({date}) || []));
      console.log('orders', orders);
      orders.forEach(o => o.name = `${o.first_name} ${o.last_name}`);
      orders.forEach(o => {
        const meal = this.getMeal(o.meal_id);
        if (meal) {
          o.mealName = meal && meal.name;
        } else {
          console.warn('could not find meal for order', o);
        }
      });
      orders.forEach(o => o.customer = this.getCustomer(o.uid));
      // orders.forEach(o => o.email = o.customer ? o.customer.email : '');
      orders.forEach(o => o.quantityEdit = o.quantity);
      orders.forEach(o => o.isFirstOrder = o.isFirstOrder ? 'yes!' : 'no!');

      orders.forEach(o => {
        // console.log('o.displayAllergies', o);
        // ensure it is an array
        if (o.displayAllergies && !o.displayAllergies.join) {
          o.displayAllergies = [o.displayAllergies];
        }
        o.displayAllergies = (o.displayAllergies || []).join(', ')
      })
      return orders;
    },
    orders() {
      let orders = this.ordersUnfiltered;
      const {streamsSelected, todSelected} = this;
      if (streamsSelected.length > 0) {
        orders = orders.filter(o => streamsSelected.includes(o.stream));
      }
      if (todSelected.length > 0) {
        orders = orders.filter(o => todSelected.includes(o.tod));
      }
      if (this.search) {
        const regex = new RegExp(escapeRegex(this.search), 'i');
        const test = (o) => {
          const string = this.headers.map(h => o[h.value]).join(' ');
          return regex.test(string);
        }
        orders = orders.filter(o => test(o));
      }
      return orders;
    },
    totalCount() {
      let count = 0;
      this.orders
        .forEach(o => count += o.quantity);
      return count;
    },
    totalCountStream() {
      let count = 0;
      const tods = 'breakfast lunch dinner'.split(' ');
      this.orders
        .filter(o => o.order_type === 'full' && tods.includes(o.tod))
        .forEach(o => count += o.quantity);
      return count;
    },
    totalCountExtras() {
      let count = 0;
      this.orders
        .filter(o => o.tod === 'extras' || o.tod === 'snack')
        .forEach(o => count += o.quantity);
      return count;
    },
    mealsByDiet() {
      console.log('this.getMealsByDiet(this.date)', this.getMealsByDiet(this.date));
      return this.getMealsByDiet(this.date);
    },
    summary() {
      return this.$store.getters.summary(this.date);
    },
    streams() {
      const streams = new Set();
      this.ordersUnfiltered.forEach(o => streams.add(o.stream));
      if (this.streamsSelected) {
        this.streamsSelected.forEach(s => streams.add(s));
      }
      // console.log('streams', streams);
      return [...streams.values()].sort();
    },
    tods() {
      return [...new Set(this.ordersUnfiltered.map(o => o.tod))];
    },
    datesFormatted() {
      const format = 'dddd MMMM D';
      if (!this.dateFrom) {
        return '';
      } else if (this.dateFrom === this.dateTo) {
        return `${moment(this.dateFrom).format(format)}`;
      } else {
        return `${moment(this.dateFrom).format(format)} - ${moment(this.dateTo).format(format)}`
      }
    },
    dateFrom() {
      return [...this.dates].sort()[0];
    },
    dateTo() {
      return [...this.dates].sort().reverse()[0];
    },
  },
  methods: {
    csvAsTable,
    csvExport,
    ...mapActions([
      'fetchDiets',
      'fetchMeals',
      'fetchMeal',
      'fetchMealIngredients',
      'fetchOrdersByDate',
      'fetchCustomers'
    ]),
    rowClicked(value) {
      const selected = this.selected;
      if (selected[value.foodid]) {
        this.$delete(selected, value.foodid);
      } else {
        this.$set(selected, value.foodid, value);
      }
    },
    getDatesInRange() {
      const dates = [];
      if (!this.dateFrom || !this.dateTo) {
        return dates;
      }
      const from = moment(this.dateFrom);
      const to = moment(this.dateTo);

      const diff = moment.duration(to.diff(from));
      if (diff.asDays() >= 0 && diff.asDays() <= 31) {
        // console.log('adding days ', {from, to})
        while (from.isSameOrBefore(to)) {
          const dateString = getDateString(from);
          // console.log('added date', dateString);
          dates.push(dateString);
          from.add(1, 'day');
        }
      } else {
        window.alert(diff.asDays() + ' days is above the limit of 7');
      }
      // console.log('getDatesInRange', dates);
      return dates;
    },
    fetchData() {
      this.error = this.post = null
      this.loading = true;

      const fetchOrdersPromise = Promise
        .all(
          this.getDatesInRange()
            .map(date => this.fetchOrdersByDate({date})),
        )
        .then((ordersByDate) => {
          const meals = {};
          // console.log('ordersByDate', ordersByDate);
          ordersByDate
            .forEach(o => o
              .forEach(order => {
                const mealCount = meals[order.meal_id] || {};
                mealCount[order.meal_size] = mealCount[order.meal_size] || 0;
                mealCount[order.meal_size] += order.quantity;
                meals[order.meal_id] = mealCount;
              }));
          // console.log('meals', meals);
          return Promise
            .all(
              Object
                .keys(meals)
                .map(id =>
                  this.fetchMeal(id)
                    .catch(e => {
                      console.warn('failed to get ingredients for ' + id, e);
                    })));
        });


      return Promise.all([
        fetchOrdersPromise,
        // this.fetchCustomers()
      ])
        .catch((e) => {
          console.error('something went wrong', e);
        })
        .finally(() => {
          if (this.ordersUnfiltered.length > 500 && this.showTable === null) {
            this.showTable = false;
          }
          this.$nextTick(() => this.loading = false);
        });
    },
    meal_sizes() {
      return 'small,medium,large'.split(',');
    },
    async closeDatePicker() {
      console.log('close date picker');
      this.showDatePicker = false;
      this.$nextTick(() => this.fetchData());
    },
    dateFormatted
    // setFilteredItems(filteredIngredients) {
    //   console.log('filteredIngredients', filteredIngredients);
    //   this.filteredIngredients = filteredIngredients;
    // }
    ,
    getEmails(orders) {
      const emails = new Set()
      orders.forEach(o => o.email && emails.add(o.email));
      // orders.forEach(o => o.email && emails.add(`${o.first_name} ${o.last_name} <${o.email}>`));
      return [...emails];
    },
    getEmailChunks(orders, chunkSize) {
      const emails = this.getEmails(orders);
      const chunks = [];
      let i = 0;
      while (i < emails.length) {
        const upper = Math.min(chunkSize + i, emails.length);
        chunks.push({chunk: `${i + 1} to ${upper}`, emails: emails.slice(i, upper)});
        i += chunkSize;
      }
      return chunks;
    },
    updateQuantity(item) {
      console.log('updating ', item);
      const oldValue = item.quantity;
      item.quantity = parseInt(item.quantityEdit || '0');
      api.put(`v2/order/update-line-item`, {
        quantity: item.quantity,
        lineItemId: item.id,
        date: item.date,
        uid: item.uid
      })
        .catch(e => {
          item.quantity = oldValue;
          item.quantityEdit = oldValue;
          console.error('update failed ', {item, error: e.message});
        });
    },
    exportFilename() {
      if (this.exportFile) {
        return this.exportFile;
      }
      const parts = [...this.dates, this.search].filter(s => !!s);
      return `orders-${parts.join('-')}`;
    },
  },
  props: {
    isAdmin: {type: Boolean, default: null, required: false}
  }
}
</script>

<style>
.quantity-input {
  width: 50px;
}

.quantity-input .v-text-field__slot input {
  text-align: right;
}
</style>
